import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom"
import Home from "./../views/Home"
import About from "./../views/About"
import Sectur from "../views/SECTUR"
import PreguntasFrecuentes from "../views/PreguntasFrecuentes"
import PreguntasFrecuentesMovil from "../views/PreguntasFrecuentes/Movil"
import Cookies from "../views/cookies"
import TerminosCondiciones from "../views/TemrinosCondiciones"
import AvisoPrivacidad from "../views/AvisoPrivacidad"
import Destinos from '../views/Destinos'
import Paquetes from "../views/Paquetes"
import PaqueteDetalle from "../views/PaqueteDetalle"
import Conocenos from "../views/Conocenos"
import PaquetesOfertas from "./../views/PaquetesOferta"
import Wrapper from "../utils/scroollTop"
import { ExtraDetail } from "../views/ExtraDetail"
import { MiCotizacion } from "../views/MiCotizacion"
import { LandingPage } from "../views/LandingPage"
import { useLandingsAll } from "../hooks/useLanding"
const RoutersApp = () => {
    const [landings] = useLandingsAll()
    return (
        <BrowserRouter>
            <Wrapper>
                <Routes>
                    {
                        landings.map(Ruta => {
                            const { IdLanding, Enlace, Slug } = Ruta
                            return (
                                <Route key={IdLanding} path={Slug} element={<LandingPage />} />
                            )
                        })
                    }
                    <Route path="/" element={<Home />} />
                    <Route path="addon-detail/:id" element={<ExtraDetail />} />
                    <Route path="nosotros" element={<About />} />
                    <Route path="sectur" element={<Sectur />} />
                    <Route path="preguntasFrecuentes" element={<PreguntasFrecuentesMovil />} />
                    <Route path="preguntas-frecuentes" element={<PreguntasFrecuentes />} />
                    <Route path="cookies" element={<Cookies />} />
                    <Route path="terminos-y-condiciones" element={<TerminosCondiciones />} />
                    <Route path="aviso-de-privacidad" element={<AvisoPrivacidad />} />
                    <Route path="destinos" element={<Destinos />} />
                    <Route path="paquetes/oferta" element={<PaquetesOfertas />} />
                    <Route path="paquetes/:idDestino/:name" element={<Paquetes />} />
                    <Route path="paquete/:clavePaquete/:folio" element={<PaqueteDetalle />} />
                    <Route path="conocemas" element={<Conocenos />} />
                    <Route path="micotizacion/:idCotizacion/:token" element={<MiCotizacion />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </Wrapper>
        </BrowserRouter>
    )
}
export default RoutersApp