import {useEffect, useState} from "react"
import { getSalidasByPaqueteId } from "../services/salidaService"
export const useSalidas = ({idPaquete}) =>{
    const [salidas, setSalidas] = useState([]);
    useEffect(() =>{
        Promise.all([getSalidasByPaqueteId({idPaquete})]).then(Response => {
            setSalidas(Response[0]);
        }).catch(Error => {
            setSalidas([]);
        })
        return () =>{
            setSalidas([]);
        }
    },[idPaquete])
    return [salidas];
}