import { useEffect, useRef, useState } from "react"
import { useLandingsAll } from "../../hooks/useLanding"
import { useLocation } from "react-router"
import LogoColor from "./../../assets/white_logo.png"
import logoSol from "./../../assets/landing/SOL.png"
import mundo from "./../../assets/landing/MUNDO.png"
import personas from "./../../assets/11.webp"
import styles from "./index.module.css"
import Preguntas from "./Preguntas"
import { useDispatch, useSelector } from "react-redux"
import { obtenerPaquetesLandingAction } from "../../duck/sliderDuck"
import { useDetallePaquete } from "../../hooks/usePaqueteDetalle"
import { formatDinero } from "../../utils/getDinero"
import { useTransition, useSpring, animated, useScroll } from '@react-spring/web'
import FormularioPasajeros from "./FormularioPasajeros/infex"
import Viajeros from "./Viajeros"
import Ventajas from "./Ventajas"
import Paquete from "./Paquete"
import PasoPaso from "./PasoPaso"
import AppReferencia from "./AppReferencia"
import WhatsappButton from "./WhatsappButton"
import FloatingCircle from "./FloatingCircle"
import Avion from "./avion"
import AvionAzul from "./AvionAzul"
import Preload from "./Preload"
import Itinerario from "./Itinerario"
import Incluye from "./Incluye"

const VERDE = "rgb(105, 163, 59)"
const AZUL = "rgb(0, 158, 226)"
const AZUL_FUERTE = "#1D1B49"
const BLANCO = "white"
const TRANSPARENTE = "transparent"

const obtenerId = (landings, enlace) => {
    const landing = landings.find(element => element.Enlace === enlace)
    if (!landing) {
        return 0
    }
    return landing.IdLanding
}
const useScrollButton = () => {
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef)
    return [myRef, executeScroll]
}
const usePaquetesLanding = () => {
    const [load, setLoad] = useState(true)
    const dispatch = useDispatch()
    const location = useLocation()
    const [landings] = useLandingsAll()
    const idLanding = obtenerId(landings, location.pathname)
    useEffect(() => {
        setLoad(true)
        dispatch(obtenerPaquetesLandingAction({ IdLanding: idLanding })).then(Resposne => {
            if (Resposne.status === 200) {
                setLoad(false)
            }
        })
    }, [dispatch, idLanding])
    return [load]
}
const useAnimatedScrollX = ({ inverso = false }) => {
    const [props, set] = useSpring(() => ({
        opacity: 0,
        transform: (inverso) ? 'translateX(-50px)' : 'translateX(50px)',
    }))
    const { scrollY } = useScroll()
    set({
        opacity: scrollY.to(y => (1000 - y) / 1000),
        transform: scrollY.to(y => (inverso) ? `translateX(-${y}px)` : `translateX(${y}px)`),
    })

    return [props]
}
export const LandingPage = () => {
    const [loadLanding] = usePaquetesLanding()
    const { titulo, idPaquete, paquete } = useSelector(store => store.slider)
    const [detalle, load, error] = useDetallePaquete({ idPaquete: idPaquete })
    const [carucelRef, onClickCarrucel] = useScrollButton()
    const [propsHorizontal] = useAnimatedScrollX({ inverso: false })
    const [propsHorizontalInverso] = useAnimatedScrollX({ inverso: true })
    const [activeTab, setActiveTab] = useState(0)
    const transitions = useTransition(activeTab, {
        key: activeTab,
        from: { opacity: 0, transform: 'translate3d(0, -20px, 0)' },
        enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
        leave: { opacity: 0, transform: 'translate3d(0, -20px, 0)' },
    })

    const handleTabClick = (index) => {
        setActiveTab(index);
    }
    if (!loadLanding)
        return (
            <div style={{ overflowX: "hidden" }}>
                <div style={{ zIndex: 2, position: "relative" }}>
                    <FloatingCircle
                        size={`140px`}
                        delay={Math.random() * 3000}
                        x={`-4vw`}
                        y={`-4vh`}
                        color={`${VERDE}`}
                        background={`${TRANSPARENTE}`}
                        blur={`10px`}
                        zindex={`1`}
                    />
                    <FloatingCircle
                        size={`140px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`-4vh`}
                        color={`${VERDE}`}
                        background={`${TRANSPARENTE}`}
                        blur={`10px`}
                        zindex={`99`}
                    />
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`75vw`}
                        y={`8vh`}
                        color={`${BLANCO}`}
                        background={`${TRANSPARENTE}`}
                        blur={`5px`}
                        zindex={`99`}
                    />
                    <FloatingCircle
                        size={`140px`}
                        delay={Math.random() * 2000}
                        x={`85vw`}
                        y={`44vh`}
                        color={`${VERDE}`}
                        background={`${TRANSPARENTE}`}
                        blur={`10px`}
                        zindex={`99`}
                    />
                    <FloatingCircle
                        size={`110px`}
                        delay={Math.random() * 2000}
                        x={`-10vw`}
                        y={`105vh`}
                        color={`${TRANSPARENTE}`}
                        background={`${AZUL_FUERTE}`}
                        blur={`18px`}
                        zindex={`99`}
                    />
                    <div ref={carucelRef} className={styles.cabecera}>
                        <animated.div
                            style={{
                                ...propsHorizontal,
                            }}
                        >
                            <div style={{ margin: "0 auto", width: "100%", maxWidth: "450px" }}>
                                <img className={styles.logoLanding} src={LogoColor} alt="dooddo" />
                            </div>
                            <div>
                                <h1 className={styles.titulo}>{titulo}</h1>
                                <div className={styles.gridCabecera}>
                                    <div>
                                        <p>{paquete.Dias} <span><img className={styles.diasLogo} src={logoSol} alt="dias" /></span> </p>
                                    </div>
                                    <div>
                                        <p>{`DESDE ${formatDinero(paquete.PrecioPublico)} ${paquete.Moneda?.Clave}`}</p>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                        <animated.div
                            style={{
                                ...propsHorizontalInverso,
                            }}
                        >
                            <div className={styles.containerImages}>
                                <img className={styles.mundo} src={mundo} alt="mundo" />
                                <img className={styles.personasLogo} src={personas} alt="personas" />
                            </div>
                        </animated.div>
                        <div className={styles.cardContentForm}>
                            <div className={styles.tabsContainer}>
                                <ul className={styles.tabs}>
                                    <li><button className={activeTab === 0 ? styles.activeTap : ""} onClick={() => handleTabClick(0)}>1</button> <span>Itinerario</span></li>
                                    <li><button className={activeTab === 1 ? styles.activeTab : ""} onClick={() => handleTabClick(1)}>2</button> <span>Que inlcuye</span></li>
                                    <li><button className={activeTab === 2 ? styles.activeTab : ""} onClick={() => handleTabClick(2)}>3</button> <span>Fomulario</span></li>
                                </ul>
                                <div className={styles.tabContent}>
                                    {transitions((style, index) => (
                                        <animated.div
                                            key={index}
                                            style={{
                                                ...style,
                                                position: 'relative',
                                                width: '100%',
                                            }}
                                        >
                                            {
                                                (activeTab === 0) &&
                                                <Itinerario />
                                            }
                                            {
                                                (activeTab === 1) &&
                                                <Incluye />
                                            }
                                            {
                                                (activeTab === 2) &&
                                                <FormularioPasajeros />
                                            }
                                        </animated.div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "40px", marginBottom: "40px", position: "relative" }}>
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`-1vh`}
                        color={`${VERDE}`}
                        background={`${TRANSPARENTE}`}
                        blur={`5px`}
                        zindex={`99`}
                    />
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`0vw`}
                        y={`60vh`}
                        color={`${TRANSPARENTE}`}
                        background={`${AZUL}`}
                        blur={`10px`}
                        zindex={`-1`}
                    />
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`80vh`}
                        color={`${TRANSPARENTE}`}
                        background={`${VERDE}`}
                        blur={`10px`}
                        zindex={`-1`}
                    />
                    <Viajeros />
                    <p style={{ color: "#009ee2", marginTop: "60px" }}>Nuestros viajeros</p>
                    <Ventajas />
                    <WhatsappButton />
                </div>
                <div className={styles.cabecera} style={{ paddingTop: "80px", paddingBottom: "60px", position: "relative", zIndex: 1 }}>
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`6vh`}
                        color={`${AZUL}`}
                        background={`${TRANSPARENTE}`}
                        blur={`5px`}
                        zindex={`99`}
                    />
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`5vw`}
                        y={`65vh`}
                        color={`${TRANSPARENTE}`}
                        background={`${VERDE}`}
                        blur={`10px`}
                        zindex={`2`}
                    />
                    <FloatingCircle
                        size={`150px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`40vh`}
                        color={`${TRANSPARENTE}`}
                        background={`${BLANCO}`}
                        blur={`40px`}
                        zindex={`-1`}
                    />
                    <h1 className={styles.titulo}>Tu próximo</h1>
                    <h1 className={styles.titulo}>viaje te espera</h1>
                    <br />
                    <div style={{ position: "absolute", top: -30, right: 0, left: -130, bottom: 0, margin: "auto", zIndex: -1, maxWidth: "250px" }}>
                        <AvionAzul />
                    </div>
                    <div onClick={onClickCarrucel} style={{ zIndex: "3" }} >
                        <Paquete />
                    </div>
                    <br />
                    <WhatsappButton />
                </div>
                <div style={{ margin: "50px auto", width: "100%", maxWidth: "750px", position: "relative" }}>
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`-1vh`}
                        color={`${VERDE}`}
                        background={`${TRANSPARENTE}`}
                        blur={`5px`}
                        zindex={`99`}
                    />
                    <FloatingCircle
                        size={`150px`}
                        delay={Math.random() * 3000}
                        x={`-10vw`}
                        y={`50vh`}
                        color={`${AZUL}`}
                        background={`${TRANSPARENTE}`}
                        blur={`16px`}
                        zindex={`-1`}
                    />
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`80vh`}
                        color={`${TRANSPARENTE}`}
                        background={`${VERDE}`}
                        blur={`20px`}
                        zindex={`-1`}
                    />
                    <PasoPaso />
                    <WhatsappButton />
                </div>
                <div style={{ margin: "0 auto", width: "100%", maxWidth: "750px", position: "relative" }}>

                    <FloatingCircle
                        size={`60px`}
                        delay={Math.random() * 3000}
                        x={`10vw`}
                        y={`-4vh`}
                        color={`${VERDE}`}
                        background={`${TRANSPARENTE}`}
                        blur={`5px`}
                        zindex={`99`}
                    />
                    <FloatingCircle
                        size={`150px`}
                        delay={Math.random() * 3000}
                        x={`-20vw`}
                        y={`30vh`}
                        color={`${AZUL}`}
                        background={`${TRANSPARENTE}`}
                        blur={`10px`}
                        zindex={`1`}
                    />
                    <FloatingCircle
                        size={`150px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`40vh`}
                        color={`${TRANSPARENTE}`}
                        background={`${VERDE}`}
                        blur={`12px`}
                        zindex={`1`}
                    />
                    <AppReferencia />
                </div>
                <div className={styles.cabecera} style={{ margin: "0 auto", width: "100%", maxWidth: "750px", position: "relative" }}>
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`-1vh`}
                        color={`${VERDE}`}
                        background={`${TRANSPARENTE}`}
                        blur={`5px`}
                        zindex={`99`}
                    />
                    <FloatingCircle
                        size={`150px`}
                        delay={Math.random() * 3000}
                        x={`-10vw`}
                        y={`50vh`}
                        color={`${AZUL}`}
                        background={`${TRANSPARENTE}`}
                        blur={`16px`}
                        zindex={`1`}
                    />
                    <FloatingCircle
                        size={`50px`}
                        delay={Math.random() * 3000}
                        x={`80vw`}
                        y={`80vh`}
                        color={`${TRANSPARENTE}`}
                        background={`${VERDE}`}
                        blur={`20px`}
                        zindex={`1`}
                    />
                    <Preguntas />
                    <div style={{ position: "relative", zIndex: 1 }}>
                        <WhatsappButton />
                        <div className={styles.avionIconoContentFooter}>
                            <Avion />
                        </div>
                        <div className={styles.logoContentenFooter}>
                            <img className={styles.logoLanding} src={LogoColor} alt="dooddo" />
                        </div>
                        <div className={styles.direcion}>
                            <p>Paseo Polanco, Lago Alberto 367, Anáhuac I Secc, Miguel Hidalgo, 11320 Ciudad de México, CDMX. Piso 1</p>
                        </div>
                        <div className={styles.FooterIconsContent}>
                            <a href="https://www.facebook.com/DodoViajes" target="_blank" rel="noreferrer">
                                <svg className={styles.FooterIcons} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30px" height="30px"><path d="M 9 4 C 6.2504839 4 4 6.2504839 4 9 L 4 41 C 4 43.749516 6.2504839 46 9 46 L 25.832031 46 A 1.0001 1.0001 0 0 0 26.158203 46 L 31.832031 46 A 1.0001 1.0001 0 0 0 32.158203 46 L 41 46 C 43.749516 46 46 43.749516 46 41 L 46 9 C 46 6.2504839 43.749516 4 41 4 L 9 4 z M 9 6 L 41 6 C 42.668484 6 44 7.3315161 44 9 L 44 41 C 44 42.668484 42.668484 44 41 44 L 33 44 L 33 30 L 36.820312 30 L 38.220703 23 L 33 23 L 33 21 C 33 20.442508 33.05305 20.398929 33.240234 20.277344 C 33.427419 20.155758 34.005822 20 35 20 L 38 20 L 38 14.369141 L 37.429688 14.097656 C 37.429688 14.097656 35.132647 13 32 13 C 29.75 13 27.901588 13.896453 26.71875 15.375 C 25.535912 16.853547 25 18.833333 25 21 L 25 23 L 22 23 L 22 30 L 25 30 L 25 44 L 9 44 C 7.3315161 44 6 42.668484 6 41 L 6 9 C 6 7.3315161 7.3315161 6 9 6 z M 32 15 C 34.079062 15 35.38736 15.458455 36 15.701172 L 36 18 L 35 18 C 33.849178 18 32.926956 18.0952 32.150391 18.599609 C 31.373826 19.104024 31 20.061492 31 21 L 31 25 L 35.779297 25 L 35.179688 28 L 31 28 L 31 44 L 27 44 L 27 28 L 24 28 L 24 25 L 27 25 L 27 21 C 27 19.166667 27.464088 17.646453 28.28125 16.625 C 29.098412 15.603547 30.25 15 32 15 z" /></svg>
                            </a>
                            <a href="https://www.instagram.com/dooddoviajesmx/" target="_blank" rel="noreferrer">
                                <svg className={styles.FooterIcons} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30px" height="30px"><path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z" /></svg>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        )
    return <Preload loading={loadLanding} />
}
