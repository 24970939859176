import styles from "./index.module.css"
import { useSelector } from "react-redux"
import { useSpring, animated } from '@react-spring/web'
import logo from "./../../../assets/landing/WHATSAPP_LOGO.png"
const WhatsappButton = () => {
    const { titulo } = useSelector(store => store.slider)
    const [style, api] = useSpring(() => ({
        from: { transform: 'scale(1)' },
        to: { transform: 'scale(1.1)' },
        config: { tension: 170, friction: 20 },
        loop: { reverse: true },
    }));

    return (
        <animated.div style={{...style, zIndex: 1}}>
            <a
                href={`https://wa.me/+525618653302?text=Quiero%20mas%20informacion%20del%20viaje%20${titulo.replace(" ", "%20")}`}
                target="_blank"
                className={styles.whatsappButton}
            >
                Enviar WhatsApp
                <img src={logo} alt="whatsapp" />
            </a>
        </animated.div>
    )
}
export default WhatsappButton