import "./Salidas.css"
const Salidas = (props) => {
    const { Postumo=false, Anho="", Mes="", Dia="" } = props;
    return (
        <div className="SalidasComponent">
            <h2>{Anho}</h2>
            <h3>{Mes}</h3>
            <h3>{Dia}</h3>
            <p style={{color: (Postumo) ? "#009ee2" : "#69a33b"}}>{(Postumo) ? "º" : "º"}</p>
        </div>
    )
}
export default Salidas