import { useState } from 'react'
import styles from "./index.module.css"
import { useSelector } from 'react-redux'
import logo from "./../../../assets/landing/WHATSAPP_LOGO.png"

const FormularioPasajeros = () => {
  const { titulo } = useSelector(store => store.slider)

  const [nombre, setNombre] = useState('')
  const [numeroPasajeros, setNumeroPasajeros] = useState('')
  // const [tipoViaje, setTipoViaje] = useState('')
  const [aceptarContacto, setAceptarContacto] = useState(false)


  const handleSubmit = (event) => {
    event.preventDefault()
    if (!aceptarContacto){
      alert("Acepta nuestros términos y condiciones para poder continuar.");
      return null
    }
    // let texto  = ` ${titulo} Nombre: ${nombre}, Número de pasajeros: ${numeroPasajeros}, Salida en: ${tipoViaje.replace("-", " ")}` 
    let texto  = ` ${titulo} Nombre: ${nombre}, Número de pasajeros: ${numeroPasajeros}` 
    window.open(`https://wa.me/+525618653302?text=Quiero%20mas%20informacion%20del%20viaje%20${texto.replace(" ", "%20")}`, '_blank');

    setNombre('')
    setNumeroPasajeros('')
    // setTipoViaje('')
    setAceptarContacto(false)
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <label className={styles.label}>
        <input
          type="text"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          className={styles.input}
          placeholder='Nombre'
          required
        />
      </label>
      {/* <label className={styles.label}>
        <span>Cuándo tienes pensado hacer tu viaje:</span>
        <select required value={tipoViaje} onChange={(e) => setTipoViaje(e.target.value)} className={styles.select}>
          <option value="" disabled>Selecciona una opción:</option>
          <option value="corto-plazo">Corto plazo</option>
          <option value="mediano-plazo">Mediano plazo</option>
          <option value="largo-plazo">Largo plazo</option>
        </select>
      </label> */}
      <label className={styles.label}>
        <span> Número de personas</span>
        <select required value={numeroPasajeros} onChange={(e) => setNumeroPasajeros(e.target.value)} className={styles.select}>
          <option value="" disabled>Selecciona una opción:</option>
          <option value="1-2">1-2</option>
          <option value="3-5">3-5</option>
          <option value="5-10">5-10</option>
          <option value="10+">Más de 10</option>
        </select>
      </label>
      <label className={styles.labelCheck}>
        <input
          type="checkbox"
          checked={aceptarContacto}
          onChange={(e) => setAceptarContacto(e.target.checked)}
          className={styles.checkbox}
        />
        <span className={styles.caption}>Acepto ser contactado por medio de un agente de ventas de DOODDO</span>
      </label>
      <button type="submit" className={styles.button}>
        Enviar WhatsApp
        <img src={logo} alt="whatsapp" />
      </button>
    </form>
  );
};
export default FormularioPasajeros;