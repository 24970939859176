import {useEffect, useState} from "react"
import { getLandingAllHabilitadas } from "../services/landingServiece"
export const useLandingsAll = () =>{
    const [landings, setLadings] = useState([])
    useEffect(() =>{
        Promise.all([getLandingAllHabilitadas()]).then(Response => {
            setLadings(Response[0]);
        }).catch(Error => {
            setLadings([])
        })
        return () =>{
            setLadings([])
        }
    },[])
    return [landings]
}